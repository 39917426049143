import { ServerCartDelivery } from "src/redux/cart/serverCart/types";
import getDeliveryPresetId from "./getDeliveryPresetId";

const getIsActivePreset = (preset: ServerCartDelivery, selectedPreset: ServerCartDelivery) => {
  const presetId = getDeliveryPresetId(preset);
  const selectedPresetId = getDeliveryPresetId(selectedPreset);

  return !!selectedPresetId && !!presetId && presetId === selectedPresetId;
};

export default getIsActivePreset;
