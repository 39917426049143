import { createAction, createReducer } from '@reduxjs/toolkit';
import { modalsManager } from '../../utils/system/modalsManager';
import { CloseModalAction, ModalData, ModalName, ModalsState, OpenModalAction, SharedData, SwitchModalAction } from './types';

export const modalsInitialState: ModalsState = {
  loginRecoveryModal: {
    isOpen: false,
  },
  addressModal: {
    isOpen: false,
    data: null,
  },
  cartModal: {
    isOpen: false,
  },
  loginOrRegistrationModal: {
    isOpen: false,
    data: null,
  },
  productCardModal: {
    isOpen: false,
    data: null,
  },
  productLackModal: {
    isOpen: false,
  },
  leaveFeedbackModal: {
    isOpen: false,
    data: null,
  },
  unexpectedErrorModal: {
    isOpen: false,
    data: null,
  },
  createListModal: {
    isOpen: false,
    data: null,
  },
  addToListModal: {
    isOpen: false,
    data: null,
  },
  registrationSuccessModal: {
    isOpen: false,
    data: null,
  },
  availableDeliverySlotsModal: {
    isOpen: false,
  },
  noAvailableSlotsModal: {
    isOpen: false,
  },
  confirmAgeModal: {
    isOpen: false,
  },
  servicePagesModal: {
    isOpen: false,
    data: null,
  },
  cartResolveModal: {
    isOpen: false,
    data: null,
  },
  contentSorterModal: {
    isOpen: false,
    data: null,
  },
  shareModal: {
    isOpen: false,
    data: null,
  },
  cartReplacementsModal: {
    isOpen: false,
    data: null,
  },
  replacementProductModal: {
    isOpen: false,
    data: null,
  },
  sharedData: {},
  reviewProductModal: {
    isOpen: false,
    data: null,
  },
  confirmTheNewTermsModal: {
    isOpen: false,
  },
  addToModal: {
    isOpen: false,
  },
  recommendationsModal: {
    isOpen: false,
  },
  upSaleProductsModal: {
    isOpen: false,
    data: null,
  },
  npPostomatModal: {
    isOpen: false,
  },
  paymentSuccessModal: {
    isOpen: false,
  },
};

const openModalAction = createAction<OpenModalAction>('modals/openModal');
const closeModalAction = createAction<CloseModalAction>('modals/closeModal');
const switchModalAction = createAction<SwitchModalAction>('modals/switchModal');

export const openModal = (
  modalName: ModalName,
  modalData: ModalData | null = null,
  sharedData?: SharedData,
) => openModalAction({
  modalName,
  modalData,
  sharedData,
});

export const closeModal = (
  modalName: ModalName,
) => (dispatch) => {
  dispatch(closeModalAction({
    modalName,
  }));
  modalsManager.showFirstModal(dispatch);
};

export const switchModal = (
  oldModalName: ModalName,
  newModalName: ModalName,
  modalData?: ModalData,
  sharedData?: SharedData,
) => switchModalAction({
  oldModalName,
  newModalName,
  modalData,
  sharedData,
});

const reducer = createReducer(modalsInitialState, builder => {
  builder
    .addCase(openModalAction, (state, action) => {
      const { modalName, modalData, sharedData } = action.payload;
      state[modalName].isOpen = true;
      state[modalName].data = modalData || null;
      state.sharedData = {
        ...state.sharedData,
        ...sharedData,
      };
    })
    .addCase(closeModalAction, (state, action) => {
      const { modalName } = action.payload;
      state[modalName].isOpen = false;
      state[modalName].data = null;
    })
    .addCase(switchModalAction, (state, action) => {
      const { oldModalName, newModalName, modalData, sharedData } = action.payload;
      state[oldModalName].isOpen = false;
      state[newModalName].isOpen = true;
      state[newModalName].data = modalData || null;
      state.sharedData = {
        ...state.sharedData,
        ...sharedData,
      };
    });
});

export default reducer;
