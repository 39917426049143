import { ServerCartDelivery } from "src/redux/cart/serverCart/types";

const getDeliveryPresetId = (serverCartDelivery: ServerCartDelivery | null): string => {
  switch (serverCartDelivery?.type) {
    case 'plan':
      return serverCartDelivery.address.plan.id;
    case 'pickup':
      return serverCartDelivery.address.pickup.id;
    default:
      return '';
  }
};

export default getDeliveryPresetId;
