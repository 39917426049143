import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import { selectAppConfig } from '../../../utils/appConfig/selectAppConfig';
import { ServerCartData, ServerCartState } from './types';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import getSuccessPayload from './getSuccessPayload';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { CartAlternativeItem } from '../general/cartTypes';

const postCartAltItemRequest = createAction('serverCart/postCartAltItemRequest');
export const postCartAltItemSuccess = createAction<ServerCartData>('serverCart/postCartAltItemSuccess');
const postCartAltItemError = createAction<RSAAActionErrorPayload>('serverCart/postCartAltItemError');

const requestPostCartAltItem = (
  parentEan: string,
  altItem: CartAlternativeItem,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/items/${parentEan}/alternative`,
      credentials: 'include',
      body: JSON.stringify(altItem),
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
        'Content-Type': 'application/json',
      },
      types: [
        postCartAltItemRequest.type,
        {
          type: postCartAltItemSuccess.type,
          payload: getSuccessPayload,
        },
        postCartAltItemError.type,
      ],
    }),
  );
};

export function addPostCartAltItemCases(
  builder: ActionReducerMapBuilder<ServerCartState>,
) {
  builder
    .addCase(postCartAltItemRequest, (state) => {
      state.statusMap.postAltItem = 'loading';
      state.errorMap.postAltItem = null;
    })
    .addCase(postCartAltItemSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postAltItem = 'succeeded';
      state.errorMap.postAltItem = null;
      state.errors = null;
    })
    .addCase(postCartAltItemError, (state, action) => {
      state.statusMap.postAltItem = 'failed';
      state.errorMap.postAltItem = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}

export default requestPostCartAltItem;
