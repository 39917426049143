import { createSelector, Selector } from '@reduxjs/toolkit';
import { kilosToGrams } from 'src/utils/product/weightConverter';
import { ApiErrorShape, DeliveryType } from '../../apiTypes';
import { RootState } from '../../reducers';
import { ServerCartItem, ServerCartMarkers } from './types';

export const selectServerCartItems: Selector<RootState, ServerCartItem[]> = (
  state,
) => state.serverCart.data.items;

export const selectServerDeliveryType: Selector<RootState, DeliveryType> = (
  state,
) => state.serverCart.data.delivery ? state.serverCart.data.delivery.type : null;

export const selectServerCartOverweight: Selector<RootState,null | number> = createSelector(
  [
    (state: RootState) => state.serverCart.data.delivery?.address?.nova_poshta,
    (state: RootState) => state.serverCart.data.total_weight,
  ],
  (novaPoshta, totalWeight) => {

    if (!novaPoshta?.warehouse) {
      return null;
    }

    const max_weight_allowed =  novaPoshta.warehouse.max_weight_allowed || 0;
    const maxWeightOfDepartment = kilosToGrams(max_weight_allowed);
    const overweight = totalWeight - maxWeightOfDepartment;

    return overweight > 0 ? overweight : null;
  },
);

export const selectServerCartAvailabilityWarnings: Selector<RootState, ApiErrorShape[] > = createSelector(
  [
    state => state.serverCart.data.warnings,
  ],
  warnings => warnings.filter(warning => warning.error_code === 4160 ||
    warning.error_code === 4164 || warning.error_code === 4345),
);

export const selectCourierComment: Selector<
  RootState, string
> = createSelector(
  [
    (state: RootState) => state.serverCart.data.delivery,
  ],
  ( delivery) => delivery?.address?.plan?.comments || '',
);

export const selectServerCartMarkers: Selector<RootState, ServerCartMarkers> = (state) => state.serverCart.data.markers;
