import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { ServerCartData, ServerCartState } from './types';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from '../../../utils/appConfig/selectAppConfig';
import { RootState } from '../../reducers';
import { Dispatch } from 'redux';
import getSuccessPayload from './getSuccessPayload';

const postCartAltItemsRequest = createAction('serverCart/postCartAltItemsRequest');
export const postCartAltItemsSuccess = createAction<ServerCartData>('serverCart/postCartAltItemsSuccess');
const postCartAltItemsError = createAction<RSAAActionErrorPayload>('serverCart/postCartAltItemsError');

interface RequestItemDto {
  'cart_item_ean': string;
  'ean': string;
  'amount': number;
}

type RequestPostCartAltItemsBody = RequestItemDto[];

const requestPostCartAltItems = (body: RequestPostCartAltItemsBody) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/alternatives/`,
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postCartAltItemsRequest.type,
        {
          type: postCartAltItemsSuccess.type,
          payload: getSuccessPayload,
        },
        postCartAltItemsError.type,
      ],
    }),
  );
};

export function addPostCartAltItemsCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartAltItemsRequest, (state) => {
      state.statusMap.postCartAltItems = 'loading';
      state.errorMap.postCartAltItems = null;
    })
    .addCase(postCartAltItemsSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postCartAltItems = 'succeeded';
      state.errorMap.postCartAltItems = null;
    })
    .addCase(postCartAltItemsError, (state, action) => {
      state.statusMap.postCartAltItems = 'failed';
      state.errorMap.postCartAltItems = action.payload;
    });
}

export default requestPostCartAltItems;
