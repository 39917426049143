import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import { selectAppConfig } from '../../../utils/appConfig/selectAppConfig';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { ServerCartData, ServerCartState } from './types';
import getSuccessPayload from './getSuccessPayload';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';

const deleteCartAltItemRequest = createAction('serverCart/deleteCartAltItemRequest');
export const deleteCartAltItemSuccess = createAction<ServerCartData>('serverCart/deleteCartAltItemSuccess');
const deleteCartAltItemFailure = createAction<RSAAActionErrorPayload>('serverCart/deleteCartAltItemFailure');

const requestDeleteCartAltItem = (parentEan: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'DELETE',
      endpoint: `${apiRoot}/cart/items/${parentEan}/alternative`,
      credentials: 'include',
      headers: {
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        deleteCartAltItemRequest.type,
        {
          type: deleteCartAltItemSuccess.type,
          payload: getSuccessPayload,
        },
        deleteCartAltItemFailure.type,
      ],
    }),
  );
};

export function addDeleteCartAltItemCases(
  builder: ActionReducerMapBuilder<ServerCartState>,
) {
  builder
    .addCase(deleteCartAltItemRequest, (state) => {
      state.statusMap.deleteAltItem = 'loading';
      state.errorMap.deleteAltItem = null;
    })
    .addCase(deleteCartAltItemSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.deleteAltItem = 'succeeded';
      state.errorMap.deleteAltItem = null;
      state.errors = null;
    })
    .addCase(deleteCartAltItemFailure, (state, action) => {
      state.statusMap.deleteAltItem = 'failed';
      state.errorMap.deleteAltItem = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}


export default requestDeleteCartAltItem;
