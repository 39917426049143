import { useDispatch } from 'react-redux';
import setNewDelivery from 'src/components/containers/AddressManagementModal/setNewDelivery';
import getIsActivePreset from 'src/modules/AddressManagement/helpers/getIsActivePreset';
import { Store } from 'src/redux/apiTypes';
import selectSelectedDelivery from 'src/redux/cart/general/selectSelectedDelivery';
import { useAppSelector } from 'src/redux/hooks';
import { DeliveryPreset } from './types';
import useDeliveryPresetsRequests from './useDeliveryPresetsRequests';

export default function useDeliveryPresetsService(isLoggedIn: boolean, store: Store) {
  const {
    requestGetDeliveryPresets,
    requestDeleteDeliveryPresets,
    requestPutDeliveryPresets,
    ...rest
  } = useDeliveryPresetsRequests(isLoggedIn);

  const dispatch = useDispatch();
  const delivery = useAppSelector(selectSelectedDelivery);

  const editDeliveryPreset = async (preset: DeliveryPreset) => {
    await requestPutDeliveryPresets(preset);
    await requestGetDeliveryPresets();

    const presetIsUserDelivery = getIsActivePreset(preset, delivery);

    if (presetIsUserDelivery) {
      await dispatch(setNewDelivery(preset, store, store));
    }
  };

  const deleteDeliveryPreset = async (id: string) => {
    await requestDeleteDeliveryPresets(id);
    await requestGetDeliveryPresets();
  };

  return {
    updateDeliveryPresets: requestGetDeliveryPresets,
    editDeliveryPreset,
    deleteDeliveryPreset,
    ...rest,
  };
}
