import { createSelector, Selector } from '@reduxjs/toolkit';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { selectProductsData } from '../../product/productSelectors';
import { RootState } from '../../reducers';
import { ClientCartPrice } from '../clientCart/clientCartTypes';
import {
  selectClientCartItems,
  selectClientCartOverweight,
} from '../clientCart/selectors';
import {
  selectServerCartAvailabilityWarnings,
  selectServerCartItems,
  selectServerCartOverweight,
} from '../serverCart/selectors';
import { ServiceFeeInfo, StairsDiscount } from '../serverCart/types';
import { CartItem } from './cartTypes';
import selectNovaPoshtaType from './selectNovaPoshtaType';

export const selectCartOverweight: Selector<RootState, null | number> = (
  state,
) => {
  const cartType = state.app.cartType;

  if (cartType === null) {
    return null;
  }

  if (cartType === 'client') {
    return selectClientCartOverweight(state);
  }

  return selectServerCartOverweight(state);
};

export const selectCartItems: Selector<RootState, CartItem[]> = createSelector(
  [
    state => state.app.cartType,
    selectServerCartAvailabilityWarnings,
    selectClientCartItems,
    selectServerCartItems,
    selectProductsData,
    selectNovaPoshtaType,
  ],
  (
    cartType,
    serverCartAvailabilityWarnings,
    clientCartItems,
    serverCartItems,
    productsData,
    novaPoshtaType,
  ) => {
    if (cartType === null) {
      return [];
    }

    if (cartType === 'client') {
      if (novaPoshtaType) {

        return clientCartItems.map((cartItem) => {
          const { product, isAvailable } = cartItem;
          const isAvailableItem = novaPoshtaType === 'postomat' ? (!product.excisable && isAvailable) : isAvailable;

          return {
            ...cartItem,
            isAvailable: isAvailableItem,
          };
        });

      } else {
        return clientCartItems;
      }
    }

    const productsMap = Array.isArray(productsData) ?
      productsData.reduce((acc, product) => {
        acc[product.ean] = product;
        return acc;
      }, {}) : {};

    return serverCartItems.map((cartItem) => {
      const { ean, unit, amount, comments, currency, price, discount, alternative } = cartItem;
      const product = productsMap[cartItem.ean] || null;
      const isAvailable = !serverCartAvailabilityWarnings.some((w) => w.field.includes(ean));

      // I don't use spread because there are
      // few properties in server cart items
      // which has not in client cart items (bundle)
      return {
        ean,
        unit,
        amount,
        isAvailable,
        product,
        comments,
        currency,
        price,
        discount,
        alternative,
      };
    });
  },
);

export interface CartPriceAdapter extends ClientCartPrice {
  price?: ClientCartPrice['price'] & { subtotal_excisable?: number }
}

export const selectCartPrice: Selector<RootState, CartPriceAdapter> = createSelector(
  [
    (state: RootState) => state.app.cartType,
    (state: RootState) => state.cart.cartPrice,
    (state: RootState) => state.serverCart.data,
  ],
  (cartType, clientCartPrice, serverCartData) => {
    if (cartType === null) {
      return {};
    }

    if (cartType === 'client') {
      return clientCartPrice;
    }

    const result: CartPriceAdapter = {
      total_weight: serverCartData.total_weight,
    };

    if (serverCartData.price) {
      const { is_approximate, ...restPrice } = serverCartData.price;

      result.price = {
        ...restPrice,
        is_approximated: is_approximate, // different properties names
      };
    }

    return result;
  },
);

export const selectCartWeight: Selector<RootState, number> = createSelector(
  [
    selectCartPrice,
  ],
  (clientCartPrice) => clientCartPrice?.total_weight || 0,
);

export const selectHasExcisableProducts: Selector<RootState, boolean> = (state) => {
  const { country } = selectAppConfig(state);
  const items = selectCartItems(state);

  return country !== 'md' && !!items.find((item) => item.product?.excisable);
};

export const selectUnavailableCartItems: Selector<
  RootState,
  CartItem[]
> = createSelector(
  selectCartItems,
  cartItems => cartItems.filter(i => !i.isAvailable),
);

export function selectStairsDiscount(state: RootState): StairsDiscount {
  const cartType = state.app.cartType;
  const serverCartStairsDiscounts = state.serverCart.data.stairs_discounts;
  const clientCartStairsDiscounts = state.cart.cartPrice.stairs_discounts;

  if (!cartType) {
    return null;
  }

  if (cartType === 'client') {
    return clientCartStairsDiscounts?.[0] || null;
  }

  return serverCartStairsDiscounts?.[0] || null;
}

export const selectServiceFeeInfo: Selector<RootState, ServiceFeeInfo | null> =
  createSelector(
    [
      (state) => state.app.cartType,
      (state) => state.serverCart.data.stairs_discounts,
      (state) => state.cart.cartPrice.stairs_discounts,
    ],
    (cartType, serverCartStairsDiscounts, clientCartStairsDiscounts) => {
      if (!cartType) {
        return null;
      }

      const stairsDiscount =
        cartType === 'server'
          ? serverCartStairsDiscounts?.[0]
          : clientCartStairsDiscounts?.[0];

      return convertStairsDiscountToServiceFee(stairsDiscount);
    },
  );

function convertStairsDiscountToServiceFee(
  stairsDiscount: StairsDiscount,
): ServiceFeeInfo {
  if (!stairsDiscount || stairsDiscount.applicable_for !== 'service_fee') {
    return null;
  }

  return {
    type: 'stairs',
    amount: stairsDiscount.current_discount,
    details: {
      amount_for_next_step: stairsDiscount.subtotal_to_next_step,
      current_economy: stairsDiscount.current_discount,
      economy_on_next_step: stairsDiscount.next_step_discount,
      steps: stairsDiscount.steps.map((step) => ({
        min: step.min,
        max: step.max,
        percentage: step.amount,
        current: step.is_current,
      })),
      current_subtotal: stairsDiscount.current_subtotal,
    },
    percentage: stairsDiscount.steps.find((step) => step.is_current).amount,
  };
}
