import dynamic from 'next/dynamic';
import { FC, Fragment } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import LoadingBarIndicator from '../../views/LoadingBarIndicator/LoadingBarIndicator';

export const ModalLoader = () => (
  <div
    style={{
      background: 'rgb(255, 255, 255, 0.5)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 300,
      top: 0,
      left: 0,
    }}
  >
    <LoadingBarIndicator />
  </div>
);

const GeneralModalProduct = dynamic(
  () => import('../GeneralModalProduct/GeneralModalProduct'),
  { ssr: false,  loading: ModalLoader },
);

const NoAvailableSlotsModal = dynamic(
  () => import('../NoAvailableSlotsModal/NoAvailableSlotsModal'),
  { ssr: false, loading: ModalLoader },
);

const Cart = dynamic(
  () => import('../Cart/Cart'),
  { ssr: false, loading: ModalLoader },
);

const GeneralModalLoginRecovery = dynamic(
  () => import('../GeneralModalLoginRecovery/GeneralModalLoginRecovery'),
  { ssr: false, loading: ModalLoader },
);

const CartsResolveModal = dynamic(
  () => import('../CartsResolveModal/CartsResolveModal'),
  { ssr: false, loading: ModalLoader },
);

const RegistrationSuccessModal = dynamic(
  () => import('../RegistrationSuccessModal/RegistrationSuccessModal'),
  { ssr: false, loading: ModalLoader },
);

const GeneralModalAuth = dynamic(
  () => import('../GeneralModalAuth/GeneralModalAuth'),
  { ssr: false, loading: ModalLoader },
);

const CreateListModal = dynamic(
  () => import('../../../modules/Favorites').then(module => module.CreateListModal),
  { ssr: false, loading: ModalLoader },
);

const ReplacementsModal = dynamic(
  () => import('../../../modules/CartReplacementsModal/CartReplacementsModal'),
  { ssr: false, loading: ModalLoader },
);

const AvailableDeliverySlotsModal = dynamic(
  () => import('../AvailableDeliverySlotsModal/AvailableDeliverySlotsModal'),
  { ssr: false, loading: ModalLoader },
);

const ReplacementProductModal = dynamic(
  () => import('../ReplacementProductModal/ReplacementProductModal'),
  { ssr: false, loading: ModalLoader },
);

const FeedbackModal = dynamic(
  () => import('../FeedbackModal/FeedbackModal'),
  { ssr: false, loading: ModalLoader },
);

const AddToListModal = dynamic(
  () => import('../../../modules/Favorites').then(module => module.AddToListModal),
  { ssr: false, loading: ModalLoader },
);

const ConfirmAgeModal = dynamic(
  () => import('../ConfirmAgeModal/ConfirmAgeModal'),
  { ssr: false, loading: ModalLoader },
);

const ServicePagesModal = dynamic(
  () => import('../ServicePagesModal/ServicePagesModal'),
  { ssr: false, loading: ModalLoader },
);

const ProductLackModal = dynamic(
  () => import('../ProductLackModal/ProductLackModal'),
  { ssr: false, loading: ModalLoader },
);

const ContentSorterModal = dynamic(
  () => import('../ContentSorterModal/ContentSorterModal'),
  { ssr: false, loading: ModalLoader },
);

const ShareModal = dynamic(
  () => import('../ShareModal/ShareModal'),
  { ssr: false, loading: ModalLoader },
);

const UnexpectedErrorModal = dynamic(
  () => import('../UnexpectedErrorModal/UnexpectedErrorModal'),
  { ssr: false, loading: ModalLoader },
);

const CustomerReviewsProductModal  = dynamic(
  () => import('../CustomerReviewsProductModal/CustomerReviewsProductModal'),
  { ssr: false, loading: ModalLoader },
);
const ConfirmTheNewTermsModal  = dynamic(
  () => import('../ConfirmTheNewTermsModal/ConfirmTheNewTermsModal'),
  { ssr: false, loading: ModalLoader },
);

const AddressManagementModal = dynamic(
  () => import('../AddressManagementModal/AddressManagementModal'),
  { ssr: false, loading: ModalLoader },
);

const AddToModal = dynamic(
  () => import('../AddToModal/AddToModal'),
  { ssr: false, loading: ModalLoader },
);

const RecommendationsModal = dynamic(
  () => import('src/modules/RecommendationsModal'),
  { ssr: false, loading: ModalLoader },
);

const UpSaleProductsModal = dynamic(
  () => import('../UpSaleProductsModal/UpSaleProductsModal'),
  { ssr: false, loading: ModalLoader },
);

const NovaPoshtaPostomatModal = dynamic(
  () => import('../NovaPoshtaPostomatModal/NovaPoshtaPostomatModal'),
  { ssr: false, loading: ModalLoader },
);

const PaymentSuccessModal = dynamic(
  () => import('../PaymentSuccessModal/PaymentSuccessModal'),
  { ssr: false, loading: ModalLoader },
);

const Modals: FC = () => {
  const modals = useAppSelector(s => s.modals);

  return (
    <Fragment>
      { modals.cartModal.isOpen && <Cart /> }
      { modals.recommendationsModal.isOpen && <RecommendationsModal /> }
      { modals.productCardModal.isOpen && <GeneralModalProduct /> }
      { modals.loginRecoveryModal.isOpen && <GeneralModalLoginRecovery /> }
      { modals.createListModal.isOpen && <CreateListModal /> }
      { modals.loginOrRegistrationModal.isOpen && <GeneralModalAuth /> }
      { modals.registrationSuccessModal.isOpen && <RegistrationSuccessModal /> }
      { modals.cartResolveModal.isOpen && <CartsResolveModal /> }
      { modals.cartReplacementsModal.isOpen && <ReplacementsModal />}
      { modals.replacementProductModal.isOpen && <ReplacementProductModal />}
      { modals.availableDeliverySlotsModal.isOpen && <AvailableDeliverySlotsModal />}
      { modals.noAvailableSlotsModal.isOpen && <NoAvailableSlotsModal />}
      { modals.leaveFeedbackModal.isOpen && <FeedbackModal />}
      { modals.addressModal.isOpen && <AddressManagementModal />}
      { modals.addToListModal.isOpen && <AddToListModal /> }
      { modals.confirmAgeModal.isOpen && <ConfirmAgeModal />}
      { modals.servicePagesModal.isOpen && <ServicePagesModal />}
      { modals.productLackModal.isOpen && <ProductLackModal />}
      { modals.contentSorterModal.isOpen && <ContentSorterModal />}
      { modals.shareModal.isOpen && <ShareModal />}
      { modals.unexpectedErrorModal.isOpen && <UnexpectedErrorModal />}
      { modals.reviewProductModal.isOpen && <CustomerReviewsProductModal />}
      { modals.confirmTheNewTermsModal.isOpen && <ConfirmTheNewTermsModal />}
      { modals.addToModal.isOpen && <AddToModal />}
      { modals.upSaleProductsModal.isOpen && <UpSaleProductsModal />}
      { modals.npPostomatModal.isOpen && <NovaPoshtaPostomatModal />}
      { modals.paymentSuccessModal.isOpen && <PaymentSuccessModal />}
    </Fragment>
  );
};

export default Modals;
