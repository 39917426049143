import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import requestPostCartItems from '../requestPostCartItems';
import { CartItem } from '../../general/cartTypes';
import { Dispatch } from 'redux';
import requestPostCartAltItems from '../requestPostCartAltItems';

/**
 * This operation is used without the queue logic for adding/deleting cart items.
 * (requestPostCartAltItems has no queue of changing)
 * It should only be used during app initialization to migrate the client cart to server.
 * */
export const setServerCartItemsWithAlts = (items: CartItem[]) => async(dispatch: Dispatch) => {
  const sendingItems = getSendingToCartItems(items, 'set');
  const itemsWithAlts = items.filter(i => !!i.alternative).map((i) => ({
    'cart_item_ean': i.ean,
    ean: i.alternative.ean,
    amount: i.alternative.amount,
  }));

  await dispatch(requestPostCartItems(sendingItems));

  if (itemsWithAlts.length > 0) {
    await dispatch(requestPostCartAltItems(itemsWithAlts));
  }
};
